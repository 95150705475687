#home-page {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.bg-f8bet {
    background-image: url('../images/bg_f8bet.png');
}
.sunrise {
    position: absolute;
    top: 0;
    left: 50%;
    height: 150%;
    z-index: 1;
    opacity: 0.4;
    animation: rotate 60s infinite linear;
}

@keyframes rotate {
    from {
        transform: translateX(-50%) translateY(-25%) rotate(0deg);
      }
      to {
        transform: translateX(-50%) translateY(-25%) rotate(359deg);
      }
}

.warpper {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 5;
}

.logo {
    width: 420px;
    margin: 30px;
}

.text-img {
    width: 500px;
    margin-bottom: -20px;
    margin-top: 20px;
}

.lixi {
    display: none;
}

.character-pic {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 3;
}

.input-cont label {
    color: white;
    font-weight: 600;
    font-size: 20px;
}

.input-cont {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 600px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 500;
}

.input-cont-f8bet {
    background-image: url('../images/input_cont_f8bet.png');
}

.input-item-cont {
    width: 75%;
    margin: 0px 0 10px;
    height: 50px;
    background-color: #e4e4e4;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 12px;
}

.input-item-cont input {
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 6px 24px;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
}

.input-item-cont input:focus {
    outline: none;
}

#submit-btn {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    font-size: 1.1em; 
    margin-bottom: 30px;
}

.submit-btn-f8bet {
    background-image: url('../images/button-f8bet.png');
    width: 256px;
    height: 55px;
}


.submit-btn-f8bet:hover {
    background-image: url('../images/buttonhover-f8bet.png');
}

#promo-detail-btn {
    margin-top: 24px;
    font-family: 'Montserrat', sans-serif;
    color: #4d95b1;
    font-weight: 600;
    animation: change-background 3s ease infinite;
    font-size: 16px;
}

@keyframes change-background {
    0% {
        color: #ebd93d;
    }
    50% {
        color: #eb572a;
    }
    100% {
        color: #ffffff;

    }
}

#promo-detail-btn:hover {
    cursor: pointer;
    color: #7a2421;
}

.promotion-modal {
    font-family: 'Times New Roman', Times, serif;
}

.header-popup {
    text-align: start !important;
    width: 100%;
}

.promo-popup {
    line-height: 30px;
}

.modal-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modal-text-cont {
    display: flex;
}

.input-form input,
.input-form-captcha input {
    padding: 0 20px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgb(206, 206, 206);
    border-radius: 10px;
    letter-spacing: 1px;
}

.input-form-captcha-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.input-form-captcha {
    width: 50%;
    height: 36px;
}

#captcha-input,
.input-form input:focus {
    outline: none;
}

#captcha {
    width: 80px;
    margin-right: 16px;
    height: 36px;
    border-radius: 5px;
    font-size: 1.2em;
    background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 3px;
    font-family: 'Mulish', sans-serif;
    font-style: italic;
    text-decoration: line-through;
    color: rgb(255, 255, 255);
}

.box-img {
    padding: 60px 0;
}

.box-label {
    padding: 20px;
}

.div-support {
    margin-bottom: 20px;   
    color: #000;
    font-size: 18px;
    font-weight: 500;   
    width: 78%;
} 
.support-content {
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 5px;
}

.support-content span {
    font-style: italic;
    font-weight: 400; 
}

.example { 
    font-weight: 600;
}
@media only screen and (max-width: 750px) {    
    .logo {
        width: 280px;
    }

    .text-img {
        margin-top: -5px;
        width: 300px;
    }

    .input-cont {
        margin: auto;
        margin-top: 20px;
        width: 95%; 
        z-index: 500;
    }


    #promo-detail-btn {
        margin-top: 20px;
        font-size: 14px;
    }

    #submit-btn {
        width: 170px;
        height: 40px;
        font-size: 0.9em;
    }

    .input-item-cont {
        width: 260px;
        margin: 16px 0 16px 0;
        height: 36px;
    }

    .box-img {
        width: 96%;
        margin: auto;
    }

    .input-cont label {
        padding: 30px 0 10px 0;
        font-size:  13px;
        text-align: center;
    }

    .submit-btn-hi88 {
        width: 256px !important;
        height: 55px !important;
        padding-left: 40px;
        padding-bottom: 5px;
    }

    .div-support { 
        font-size: 13px; 
    }
    .support-content {
        font-size: 13px; 
    }
    
    .input-item-cont input {
        background-color: transparent; 
        letter-spacing: -1px;
    }
    .modal { 
        width: 85%; 
    }
}

.popup-captcha-token {
    padding-top: 24px;
}
.popup-captcha-token .swal2-title{
    margin-bottom: 16px;
}

.thumb-captcha {
    cursor: pointer;
}
a{
    text-decoration: none;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 20px;
}
.swal2-footer a{
    color: #005bf3;
    font-size: 16px;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1000;
}

h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 1%;
    text-align: center;
}

p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-top: 20px;
}

p span {
    font-size: 16px;
    font-weight: 600;
}
table {
    width: 100%;
}

table td {
    padding: 5px;
}

table td:first-child {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px
}

table td:last-child {
    text-align: right;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px
}

.pointbonus {
    color: green;
}

.outer-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #D0F1DD;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-circle {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #D0F1DD;
    border: solid 5px white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkmark {
    width: 10px;
    height: 25px;
    border: solid #389E0D;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
}

.swal2-input {
    width: calc(100% - 2em);
    display: block;
    color: #C1C1C1;
    margin-top: 1em;
    border: solid 1px white;
    margin-left: 1em;
    background-color: #EDEDED;
    border: solid 1px white;
    border-radius: 5px;
}

.swal2-input-label {
    font-weight: 600;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
}

.promo-link-a{
    text-decoration: none;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
}
.promo-link-a:hover{
    color: #0e0e0e;
}

.account-input {
    padding: 10px 0 10px 10px;
    width: 100%;
    max-width: 370px;
    display: block;
    color: #C1C1C1;
    margin-top: 1em;
    border: solid 1px white;
    margin-left: 1em;
    background-color: #EDEDED;
    border: solid 1px white;
    border-radius: 5px;
}

.no-underline {
    text-decoration: none;
    color: #75BDD2;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 20px
}

.popup-buttons {
    margin-top: 20px;
}

.popup-button {
    background-color: #A7A7A7;
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
    color: white;
    width: 136px;
    height: 40px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    border: solid 1px white;
    cursor: pointer;
}

.home-button {
    display: inline-block;
    margin-top: 10px;
    position: absolute;
    top: 1em;
    right: 2em;
    width: 146px;
    text-align: center;
    z-index: 999;
    transition: all 0.3s ease;
    cursor: pointer;
}
.home-button:hover {
    transform: scale(1.05);
}

.home-button .home-icon-pc {
    display: inline-block;
    font-size: 18px;
}

.home-button .home-icon {
    display: none;
    width: 46px;
    height: 46px;
}

.warning-message {
    color: red;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    margin-top: 1em;
    text-align: left;
}

.popup-button.confirm {
    background: linear-gradient(to right,
            #8cb9ff 0%,
            #0066ff 100%);
}

.modal {
    display: block;
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    margin-top: 5px;
    margin: auto;
    width: 90%;
    max-width: 1000px;
    position: relative;
}

.modal-content .close {
    position: absolute;
    top: 4px;
    right: 20px;
    background-color: #cbcbcb;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Times New Roman";
}
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    background-color: #3f82a2;
}
.thumb-popup-detail {
    width: 100%;
    height: auto;
    display: flex;
}
.thumb-popup-detail .image-popup-detail {
    max-width: 100%;
    height: auto;
    margin: auto;
    max-height: 99vh;
}

.swal2-container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1000;
}

.swal2-confirm{
    background-color: #1a63dd;
}

.input-form-captcha .notranslate{
    padding: 10px;
    width: 90%;
    border-radius: 5px;
}

.input-form-captcha .notranslate:hover,
.input-form-captcha .notranslate:focus{
    border: 1px solid #1a63dd;
}

.tablet-image {
    display: none;
    position: fixed;
    bottom: 0;
    left: 20vw;
    width: 60vw;
    height: auto;
}
.swal2-actions {
    margin-top: 12px;
}

.swal2-container.swal2-center.swal2-backdrop-show {
    overflow: hidden !important;
}
.swal2-popup.swal2-modal.swal2-show {
    max-width: 90%;
}
.swal2-icon.swal2-icon-show {
    width: 4em !important;
    height: 4em !important;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}
.swal2-x-mark .swal2-x-mark-line-left, .swal2-x-mark .swal2-x-mark-line-right{
    top: 28px !important;
    width: 32px !important;
    height: 4px !important;
}
.swal2-title {
    padding-top: 6px !important;
    font-size: 26px;
}
.swal2-html-container {
    padding-top: 6px !important;
}

.pyro>.before,.pyro>.after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: -120px -218.66667px blue,248px -16.66667px #00ff84,190px 16.33333px #002bff,-113px -308.66667px #ff009d,-109px -287.66667px #ffb300,-50px -313.66667px #ff006e,226px -31.66667px #ff4000,180px -351.66667px #ff00d0,-12px -338.66667px #00f6ff,220px -388.66667px #9f0,-69px -27.66667px #ff0400,-111px -339.66667px #6200ff,155px -237.66667px #0df,-152px -380.66667px #00ffd0,-50px -37.66667px #0fd,-95px -175.66667px #a6ff00,-88px 10.33333px #0d00ff,112px -309.66667px #005eff,69px -415.66667px #ff00a6,168px -100.66667px #ff004c,-244px 24.33333px #f60,97px -325.66667px #f06,-211px -182.66667px #00ffa2,236px -126.66667px #b700ff,140px -196.66667px #9000ff,125px -175.66667px #0bf,118px -381.66667px #ff002f,144px -111.66667px #ffae00,36px -78.66667px #f600ff,-63px -196.66667px #c800ff,-218px -227.66667px #d4ff00,-134px -377.66667px #ea00ff,-36px -412.66667px #ff00d4,209px -106.66667px #00fff2,91px -278.66667px #000dff,-22px -191.66667px #9dff00,139px -392.66667px #a6ff00,56px -2.66667px #09f,-156px -276.66667px #ea00ff,-163px -233.66667px #00fffb,-238px -346.66667px #00ff73,62px -363.66667px #08f,244px -170.66667px #0062ff,224px -142.66667px #b300ff,141px -208.66667px #9000ff,211px -285.66667px #f60,181px -128.66667px #1e00ff,90px -123.66667px #c800ff,189px 70.33333px #00ffc8,-18px -383.66667px #0f3,100px -6.66667px #ff008c;
    -moz-animation: 1s bang ease-out infinite backwards,1s gravity ease-in infinite backwards,5s position linear infinite backwards;
    -webkit-animation: 1s bang ease-out infinite backwards,1s gravity ease-in infinite backwards,5s position linear infinite backwards;
    -o-animation: 1s bang ease-out infinite backwards,1s gravity ease-in infinite backwards,5s position linear infinite backwards;
    -ms-animation: 1s bang ease-out infinite backwards,1s gravity ease-in infinite backwards,5s position linear infinite backwards;
    animation: 1s bang ease-out infinite backwards,1s gravity ease-in infinite backwards,5s position linear infinite backwards
  }
  
  .pyro>.after {
    -moz-animation-delay: 1.25s,1.25s,1.25s;
    -webkit-animation-delay: 1.25s,1.25s,1.25s;
    -o-animation-delay: 1.25s,1.25s,1.25s;
    -ms-animation-delay: 1.25s,1.25s,1.25s;
    animation-delay: 1.25s,1.25s,1.25s;
    -moz-animation-duration: 1.25s,1.25s,6.25s;
    -webkit-animation-duration: 1.25s,1.25s,6.25s;
    -o-animation-duration: 1.25s,1.25s,6.25s;
    -ms-animation-duration: 1.25s,1.25s,6.25s;
    animation-duration: 1.25s,1.25s,6.25s
  }
  
  @-webkit-keyframes bang {
    from {
        box-shadow: 0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff
    }
  }
  
  @-moz-keyframes bang {
    from {
        box-shadow: 0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff
    }
  }
  
  @-o-keyframes bang {
    from {
        box-shadow: 0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff
    }
  }
  
  @-ms-keyframes bang {
    from {
        box-shadow: 0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff
    }
  }
  
  @keyframes bang {
    from {
        box-shadow: 0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff
    }
  }
  
  @-webkit-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0
    }
  }
  
  @-moz-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0
    }
  }
  
  @-o-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0
    }
  }
  
  @-ms-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0
    }
  }
  
  @keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0
    }
  }
  
  @-webkit-keyframes position {
    0%,19.9% {
        margin-top: 10%;
        margin-left: 40%
    }
  
    20%,39.9% {
        margin-top: 40%;
        margin-left: 30%
    }
  
    40%,59.9% {
        margin-top: 20%;
        margin-left: 70%
    }
  
    60%,79.9% {
        margin-top: 30%;
        margin-left: 20%
    }
  
    80%,99.9% {
        margin-top: 30%;
        margin-left: 80%
    }
  }
  
  @-moz-keyframes position {
    0%,19.9% {
        margin-top: 10%;
        margin-left: 40%
    }
  
    20%,39.9% {
        margin-top: 40%;
        margin-left: 30%
    }
  
    40%,59.9% {
        margin-top: 20%;
        margin-left: 70%
    }
  
    60%,79.9% {
        margin-top: 30%;
        margin-left: 20%
    }
  
    80%,99.9% {
        margin-top: 30%;
        margin-left: 80%
    }
  }
  
  @-o-keyframes position {
    0%,19.9% {
        margin-top: 10%;
        margin-left: 40%
    }
  
    20%,39.9% {
        margin-top: 40%;
        margin-left: 30%
    }
  
    40%,59.9% {
        margin-top: 20%;
        margin-left: 70%
    }
  
    60%,79.9% {
        margin-top: 30%;
        margin-left: 20%
    }
  
    80%,99.9% {
        margin-top: 30%;
        margin-left: 80%
    }
  }
  
  @-ms-keyframes position {
    0%,19.9% {
        margin-top: 10%;
        margin-left: 40%
    }
  
    20%,39.9% {
        margin-top: 40%;
        margin-left: 30%
    }
  
    40%,59.9% {
        margin-top: 20%;
        margin-left: 70%
    }
  
    60%,79.9% {
        margin-top: 30%;
        margin-left: 20%
    }
  
    80%,99.9% {
        margin-top: 30%;
        margin-left: 80%
    }
  }
  
  @keyframes position {
    0%,19.9% {
        margin-top: 10%;
        margin-left: 40%
    }
  
    20%,39.9% {
        margin-top: 40%;
        margin-left: 30%
    }
  
    40%,59.9% {
        margin-top: 20%;
        margin-left: 70%
    }
  
    60%,79.9% {
        margin-top: 30%;
        margin-left: 20%
    }
  
    80%,99.9% {
        margin-top: 30%;
        margin-left: 80%
    }
  }
    /* icon back */
    .back-home-container {
        position: absolute;
        left: 1rem; /* 16px */
        top: 1.25rem; /* 20px */
        z-index: 999;
    }
    .back-home-link {
        display: flex;
        max-width: 36px;
    }
    .back-home-img {
        width: 100%;
        height: auto;
    }
@media (min-width: 768px) {
    .back-home-link {
        max-width: 52px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .tablet-image {
        display: block;
        width: 60vw;
    }
    .label-text {
        font-size: 18px;
    }
    .thumb-logo {
        width: 250px;
    }
    .promo-title {
        font-size: 30px;
    }
    .promo-link-a{
        font-size: 16px;
    }
    .promo-link{
        font-size: 16px;
    }
    .promo-button{
        font-size: 18px;
    }
    .body-content {
        position: absolute;
        right: auto;
        top: 4em;
        height: auto;
        margin: 0 1em;
    }
}

@media (max-width: 767px) {
    .body-content {
        position: absolute;
        top: 2em;
        height: auto;
        width: 90%;
        margin: 0 auto;
        left: 5%;
    }
    table td:first-child, table td:last-child {
        font-size: 14px;
    }
    .swal2-input-label{
        font-size: 14px;
    }
    h2{
        font-size: 20px;
    }
    .swal2-input{
        height: 40px;
        width: 90%;
        box-sizing: border-box;
    }
    .promo-link-a{
        font-size: 12px;
    }
    .popup {
        width: 96vw;
        padding: 10px 0;
    }

    .home-button {
        position: absolute;
        top: 0em;
        right: 0.5em;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        padding: 10px;
    }

    .home-button.tablet-image {
        display: flex;
        align-items: center;
    }

    .promo-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .thumb-logo {
        max-width: 200px;
    }

    .promo-form {
        max-width: 100%;
        border-radius: 10px;
        padding: 0;
    }

    .promo-title {
        font-size: 24px;
        margin-bottom: 0;
    }

    .promo-label {
        font-size: 14px;
        margin-top: 0;
    }
    .label-text {
        font-size: 16px;
    }

    .promo-input {
        font-size: 14px;
        padding: 10px;
    }

    .promo-button {
        font-size: 14px;
        padding: 10px 20px;
        margin: 1em auto 0 auto;
    }

    .promo-links {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        margin-top: 0;
    }

    .promo-link {
        margin-top: 1em;
        margin-bottom: 0;
        text-align: left;
        border-radius: 5px;
        text-decoration: none;
        padding: 10px;
        font-size: 12px;
    }

    .arrow {
        font-size: small;
        width: 15px;
        height: 15px;
    }

    .promo-link:hover {
        background-color: #e0e0e0;
    }

    .account-input,
    .popup-button {
        height: 40px;
        width: 90%;
        box-sizing: border-box;
    }

    .popup-button {
        display: inline-block;
        text-align: center;
        padding: 0 10px;
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        border-radius: 5px;
        text-decoration: none;
        color: #333;
        width: 150px;
    }
    .home-button .home-icon-pc {
        display: none;
    }

    .home-button .home-icon {
        display: inline;
    }


    .swal2-title {
        font-size: 23px;
    }
    .swal2-html-container {
        font-size: 16px !important;
    }
    .swal2-icon.swal2-icon-show {
        width: 3em !important;
        height: 3em !important;
        margin-bottom: 8px !important;
    }
    .swal2-x-mark .swal2-x-mark-line-left, .swal2-x-mark .swal2-x-mark-line-right {
        top: 23px !important;
        width: 26px !important;
        height: 3px !important;
    }
    .swal2-x-mark .swal2-x-mark-line-left {
        left: 12px !important;
    }
    .swal2-x-mark .swal2-x-mark-line-right {
        right: 12px !important;
    }
    .swal2-icon.swal2-warning .swal2-icon-content{
        font-size: 2.75em;
    }

    .thumb-header-form {
        top: -12px;
        max-width: 300px;
        width: 86%;
    }
    .input-container {
        margin: 36px 24px 36px;
    }
    .modal-content {
        width: 92%;
    }
    .modal-content .close {
        top: 8px;
        right: 8px;
        width: 32px;
        height: 32px;
    }
    .thumb-popup-detail .image-popup-detail{
        max-height: 100%;
        width: 100%;
    }
}